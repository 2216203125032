<template>
  <div class="flex gap-x-1 w-full">
    <!-- Day -->
    <div class="relative w-full overflow-hidden">
      <select
        v-model="dateBirth.day"
        class="appearance-none"
        :class="
          customClass
            ? customClass
            : 'px-3 text-blackedsidebar font-EffraR text-base transition-colors duration-150 bg-white border border-solid border-gray-300 rounded-cu w-full focus:outline-none h-12 flex items-center justify-between cursor-pointer'
        "
      >
        <option selected>Jour</option>
        <option
          :value="('0' + day).slice(-2)"
          v-for="(day, i) in moment()(
            `${dateBirth.year}-${dateBirth.month}`,
            'YYYY-MM'
          ).daysInMonth()"
          :key="i"
        >
          {{ ("0" + day).slice(-2) }}
        </option>
      </select>
      <div
        class="absolute right-0 top-0 h-full flex items-center px-2 text-gray-900 "
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>
    <!-- Month -->
    <div class="relative w-full">
      <select
        v-model="dateBirth.month"
        class="appearance-none"
        :class="
          customClass
            ? customClass
            : 'px-3 text-blackedsidebar font-EffraR text-base transition-colors duration-150 bg-white border border-solid border-gray-300 rounded-cu w-full focus:outline-none h-12 flex items-center justify-between cursor-pointer'
        "
      >
        <option selected>Mois</option>
        <option
          :value="('0' + month).slice(-2)"
          v-for="(month, i) in 12"
          :key="i"
        >
          {{ ("0" + month).slice(-2) }}
        </option>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-900"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>
    <!-- Year -->
    <div class="relative w-full">
      <select
        v-model="dateBirth.year"
        class="appearance-none"
        :class="
          customClass
            ? customClass
            : 'px-3 text-blackedsidebar font-EffraR text-base transition-colors duration-150 bg-white border border-solid border-gray-300 rounded-cu w-full focus:outline-none h-12 flex items-center justify-between cursor-pointer'
        "
      >
        <option selected>Annee</option>
        <option :value="year" v-for="(year, i) in years" :key="i">
          {{ year }}
        </option>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-900"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["dateSave", "onSetDate", "customClass"],
  data() {
    return {
      birthday: { day: 1, month: 1, year: 1990 }
    };
  },
  methods: {
    moment() {
      return moment;
    }
  },
  watch: {
    dateBirth: {
      handler(newValue, oldValue) {
        if (newValue.day && newValue.month && newValue.year) {
          let dat = moment()
            .format(`${newValue.year}-${newValue.month}-${newValue.day}`)
            .toString();
          this.onSetDate(dat);
        }
      },
      deep: true
    }
  },
  created() {
    if (!moment(this.dateSave).isValid()) {
      return (this.birthday = { day: 1, month: 1, year: 1990 });
    }

    if (moment(this.dateSave).format("DD")) {
      this.dateBirth.day = moment(this.dateSave).format("DD");
    }

    if (moment(this.dateSave).format("MM")) {
      this.dateBirth.month = moment(this.dateSave).format("MM");
    }

    if (moment(this.dateSave).format("YYYY")) {
      this.dateBirth.year = moment(this.dateSave).format("YYYY");
    }
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 1901 + index
      );
    },
    dateBirth() {
      return this.birthday;
    }
  }
};
</script>
